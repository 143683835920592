.header {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--bg-2);
  box-shadow: 0 4px 12px rgba(var(--bg), 0.3);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;

  .title {
    flex: 0 0 auto;
    user-select: none;
    background: var(--bg-3);
    display: flex;
    font-weight: 700;
    font-size: 20px;
    align-items: center;
    align-self: stretch;
    padding: 0 18px;
    white-space: nowrap;
    cursor: pointer;

    svg {
      opacity: 0.2;
    }

    span {
      margin-left: 8px;
      display: inline-block;
    }
  }

  .nav {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 10px 5px;

    .spacer {
      flex: 1 1 auto;
    }

    > * {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  .profile {
    padding: 10px 10px 10px 0;

    > * {
      display: block;
    }

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }

    svg {
      background: var(--bg-3);
      padding: 8px;
      color: rgba(var(--fg), 0.4);
    }
  }
}

.app {
  padding-top: 60px;
}

.login-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--bg-3);
  padding: 24px;
  width: 250px;

  h1 {
    margin-top: -8px;
    margin-bottom: 8px;
  }
}

.loading-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.profile-popup {
  padding: 8px;

  span {
    font-size: 0.8em;
    opacity: 0.5;
    margin-bottom: -4px;
    display: block;
  }

  div {
    margin-bottom: 4px;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
}

.notifications {
  position: fixed;
  bottom: 0;
  right: 13px;
  width: 350px;
}

.notificationHeader {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 12px rgba(var(--bg), 0.3);
  padding: 8px;
  background: var(--bg-2);
  font-size: 16px;
  transform: translateY(100%);
  transition: transform 200ms ease;

  .counts {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 16px;
    li {
      display: flex;
      gap: 4px;
      align-items: center;
      :global(svg) {
        width: 20px;
        display: block;
        opacity: 0.5;
      }
      span {
        display: block;
        transform: translateY(1px);
      }
    }
  }
}

.visible .notificationHeader {
  transform: none;
}

.notificationsContainer {
  max-height: 150px;
  background: var(--bg-3);
  padding: 0 8px;
  transition: max-height 200ms ease;
  overflow-y: auto;
  overflow-x: hidden;

  &:hover {
    max-height: 500px;
  }
}

.notification {
  --color: rgba(var(--fg), 0.4);
  margin: 10px 0;
  background: var(--bg-1);
  display: flex;
  box-shadow: 0 4px 12px rgba(var(--bg), 0.3);
  padding: 8px;
  display: flex;
  gap: 8px;
  // width: 300px;
  border-bottom: 2px solid var(--color);
  transition: opacity 150ms;
  cursor: pointer;
  user-select: none;
  animation: 300ms slideIn forwards;

  &:hover {
    opacity: 0.4;
  }

  &.critical,
  &.error {
    --color: rgb(255, 44, 29);
  }

  &.critical {
    // background: rgb(54, 4, 1);
    background-image: repeating-linear-gradient(
      45deg,
      rgba(255, 44, 29, 0.15),
      rgba(255, 44, 29, 0.15) 10px,
      rgba(255, 44, 29, 0.1) 10px,
      rgba(255, 44, 29, 0.1) 20px
    );
  }

  svg {
    color: var(--color);
    display: block;
  }

  .body {
    line-height: 1.25;
    align-self: center;
  }

  .source {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 4px;
  }
}
